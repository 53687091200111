/**
 * 
 */

 class Tabs {

    constructor() {
        // Check if exists and initialize
        this.$tabsLinks = $('ul.tabs-links');
        if( this.$tabsLinks.length > 0 ){
            this.init();
        }
    }

    init() {
        let tabLinksA = this.$tabsLinks.find('a[data-toggle-tab]');
        tabLinksA.each(function(){
            let tabName = $(this).attr('data-toggle-tab');
            $(this).on('click', function(){
                $('div.tab').removeClass('active');
                $('a[data-toggle-tab]').removeClass('active');

                if($(this).hasClass('active')){
                    
                }else{
                    $(this).addClass('active');
                }

                $('div[data-tab="' + tabName + '"]').addClass('active');
            });
        });
    }
}

export default Tabs;