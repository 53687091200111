import Tabs from "./tabs";
import Dependents from "./dependents";
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

class Modals {

    constructor() {
        this.init();
    }

    init() {
        $(document).on('click', 'button[data-custom-close]', function(e){
            e.preventDefault();
            MicroModal.close($(this).attr('data-modal-id'));
            $(this).closest('.modal__container').removeClass('large');
            if(typeof tinyMCE !== "undefined"){
                tinyMCE.remove();
            }
        });

        /**
         * Frontend Modals
         */
        $('a[data-load-modal="plan-details"]').on('click', function (e){
            e.preventDefault();
            let modalSize =  $(this).attr('data-modal-size');

            $.ajax({
                url: '/plans/get_plan_details',
                method: 'post',
                data: {
                    'id' : $(this).attr('data-load-id'),
                    '_token': $('meta[name="csrf-token"]').attr('content'),
                },
                success: (response) => {
                    $('#modal-more-details').find('.modal__container').html(response).addClass(modalSize);
                    MicroModal.show('modal-more-details');

                },
                error: (err) => {
                    console.log(err);
                }
            });
        });

        $('a[data-load-modal="applicant-edit"]').on('click', function (e){
            e.preventDefault();
            let modalSize =  $(this).attr('data-modal-size');

            $.ajax({
                url: '/plans/get_applicant_edit',
                method: 'post',
                data: {
                    'id' : $(this).attr('data-load-id'),
                    '_token': $('meta[name="csrf-token"]').attr('content'),
                },
                success: (response) => {
                    $('#modal-applicant-edit').find('.modal__container').html(response).addClass(modalSize);
                    new Dependents();
                    MicroModal.show('modal-applicant-edit');
                },
                error: (err) => {
                    console.log(err);
                }
            });
        });

        $('body').on('click', 'a[data-load-modal="auth-modal"]', function (e){
            e.preventDefault();

            $.ajax({
                url: '/auth-modal',
                method: 'post',
                data: {
                    'quoter_selected_plan' : $(this).attr('data-load-id'),
                    'quote_number' : $(this).attr('data-quote-nr'),
                    'method' : 'apply',
                    '_token': $('meta[name="csrf-token"]').attr('content'),
                },
                success: (response) => {
                    if(response.redirect){
                        window.location.href = response.redirect;
                        return false;
                    }
                    $('#modal-auth').find('.modal__container').html(response);
                    MicroModal.show('modal-auth');
                    new Tabs();
                },
                error: (err) => {
                    console.log(err);
                }
            });
        });

        $('a[data-load-modal="auth-modal-save"]').on('click', function (e){
            e.preventDefault();

            $.ajax({
                url: '/auth-modal',
                method: 'post',
                data: {
                    'quoter_selected_plan' : $(this).attr('data-load-id'),
                    'quote_number' : $(this).attr('data-quote-nr'),
                    'method' : 'save_for_later',
                    '_token': $('meta[name="csrf-token"]').attr('content'),
                },
                success: (response) => {
                    if(response.redirect){
                        window.location.href = response.redirect;
                        return false;
                    }
                    $('#modal-auth').find('.modal__container').html(response);
                    MicroModal.show('modal-auth');
                    new Tabs();
                },
                error: (err) => {
                    console.log(err);
                }
            });
        });

        $('body').on('submit', 'form#auth-modal-register', function (e){
            e.preventDefault();

            $.ajax({
                url: $(this).attr('action'),
                method: 'post',
                data: $(this).serialize(),
                success: (response) => {
                    if(response.status == 'success'){
                        window.location.href = response.redirect;
                    }else{
                        Toast.fire({
                            icon: 'error',
                            title: response.message,
                        }).then(r => {});
                    }
                },
                error: (err) => {
                    Toast.fire({
                        icon: 'error',
                        title: err.responseJSON.message,
                    }).then(r => {});
                },
            })
        });


        $('body').on('submit', 'form#auth-modal-login', function (e){
            e.preventDefault();

            $.ajax({
                url: $(this).attr('action'),
                method: 'post',
                data: $(this).serialize(),
                success: (response) => {
                    if(response.status == 'success'){
                        window.location.href = response.redirect;
                    }else{
                        Toast.fire({
                            icon: 'error',
                            title: response.message,
                        }).then(r => {});
                    }
                },
                error: (err) => {
                    Toast.fire({
                        icon: 'error',
                        title: err.responseJSON.message,
                    }).then(r => {});
                },
            })
        });


    }
}

export default Modals;
