/**
 * Sample module for adding new functionality or website components
 * @example
 * #Add instructions here
 */

 class Dependents {

    constructor() {
        // Check if exists and initialize
        this.$dependents_list = $('.dependents-list');
        this.$has_dependents = $('#number-of-dependents');

        if( this.$has_dependents.length > 0 && this.$dependents_list.length > 0 ){
            this.init();
        }
    }

    init() {

        // Initialize component(s)
        this.$has_dependents_val = this.$has_dependents.get(0).value;
        this.$dependents_input_groups = this.$dependents_list.find( '.dependent-input-group' );
        this.$dependents_inputs = this.$dependents_list.find( '.dependent-age' );

        let isValueSelected = this.$has_dependents.get(0).value;
        if(isValueSelected){
            this.initializeDependentsInputs(isValueSelected);
        }

        this.$has_dependents.on( 'change', ()=>{
            this.$has_dependents_val = this.$has_dependents.get(0).value;
            this.initializeDependentsInputs( this.$has_dependents_val );
        });


    }

    initializeDependentsInputs( num_of_dependents ){
        let field_required, i;

        window.myDebugger = this.$dependents_inputs;

        this.$dependents_input_groups.css('display', 'none');
        this.$dependents_inputs.each( ( index, element )=>{
            $(element).removeAttr( 'required' );
        });

        for(i = 0; i < num_of_dependents; i++){
            let $currentElement = this.$dependents_inputs.eq(i);
            field_required = $currentElement.data( 'conditional-field-required' );

            if( field_required ){
                $currentElement.prop( 'required', 'true' );
            }

            this.$dependents_input_groups.eq(i).css('display', 'block');
        }
    }

}

export default Dependents;
