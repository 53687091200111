window._ = require('lodash');

try {
  window.Popper = require('@popperjs/core');
  window.$ = window.jQuery = require('jquery');

  window.bootstrap =  require('bootstrap');
} catch (e) {}

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

require('bootstrap');
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-select-bs4');
require('datatables.net-searchpanes-bs4');
require('datatables.net-responsive');
require('datatables.net-responsive-bs4');
require('jquery-validation');
require('jquery-validation/dist/additional-methods.min');
import Inputmask from "inputmask";

const { Dropzone } = require("dropzone");
const { select2 } = require("select2");


Dropzone.autoDiscover = false;


