require('./bootstrap');

import MicroModal from 'micromodal';
import Modals from './frontend/modules/modals';
import tippy from 'tippy.js';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

MicroModal.init({
    openClass: 'is-open',
    disableScroll: true,
    disableFocus: false,
    awaitOpenAnimation: false,
    awaitCloseAnimation: false,
    debugMode: false
});
const modals = new Modals();

window.showToast = (icon, message) => {
    Toast.fire({
        icon: icon,
        title: message,
    }).then(r => {});
};

$(document).ready(function(){
    tippy('[data-tippy-content]', {
        placement: 'top-start',
        touch: true,
    });

    $('body').on('change', '.form-switch', function (e){
        e.preventDefault();

        let model = $(this).attr('data-model');
        let identifier = $(this).attr('data-id');
        let url = $(this).attr('data-load');
        if(typeof url == 'undefined'){
            return false;
        }

        $.ajax({
            url: url,
            method: 'post',
            data: {
                '_token': $('meta[name="_token"]').attr('content'),
                'model': model,
                'id': identifier,
            },
            success: (response) => {
                Toast.fire({
                    icon: response.status,
                    title: response.message,
                }).then(r => {});
            },
            error: (err) => {
                console.log(err);
            }
        });
    });

    /**
     * Backend Modals
     */
    $('body').on('click', 'a[data-load-modal], button[data-load-modal]', function (e){
        e.preventDefault();
        let identifier = $(this).attr('data-load-modal');
        let modalIdentifier = identifier.replace('new-', '');
        let modalSize =  $(this).attr('data-modal-size');

        $.ajax({
            url: $(this).attr('data-load'),
            method: 'get',
            data: {
                '_token': $('meta[name="csrf-token"]').attr('content'),
            },
            success: (response) => {
                $('#modal-' + modalIdentifier).find('.modal__container').html(response);
                $('#modal-' + modalIdentifier).find('.modal__container').addClass(modalSize);
                MicroModal.show('modal-' + modalIdentifier);

            },
            error: (err) => {
                console.log(err);
            }
        });
    });

    $('body').on('submit', 'form[name="ajax-form"]', function(e){
        e.preventDefault()

        let formData = $(this).serializeArray();
        let formUrl = $(this).attr('action');
        let reloadTable = $(this).attr('data-table-reload');
        let modalInit = $(this).attr('data-modal');

        $.ajax({
            url: formUrl,
            method: 'post',
            data: formData,
            success: (response) => {
                $('#ajax-error').addClass('d-none');
                if(reloadTable){
                    let table = $('#' + reloadTable).DataTable();
                    table.draw();
                    MicroModal.close(modalInit);
                }
                Toast.fire({
                    icon: response.status ?? 'success',
                    title: response.message ?? 'Operation Completed',
                }).then(r => {});

            },
            error: (err) => {
                $('#ajax-error').text(err.responseJSON.message).removeClass('d-none');
                Toast.fire({
                    icon: 'error',
                    title: 'Error: '+ err.responseJSON.message,
                }).then(r => {});
            }
        });
    });

    /**
     * Backend Redirects
     */
    $('body').on('click', 'a[data-load-redirect], button[data-load-redirect]', function (e){
        window.location.href = $(this).attr('data-load-redirect');
    });
});
